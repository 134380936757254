<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6" v-if="variantlar.length > 0">
          <b-form-group label="Variant Seneceği">
            <v-select
              v-model="form.variant_k_no"
              :options="variantlar"
              :reduce="(diller) => diller.k_no"
              label="baslik"
              :clearable="true"
              multiple
              class="invoice-filter-select d-block select-size-lg pr-2"
              style="min-width: 280px"
              @input="createVariant($event)"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" v-if="variantlar.length > 0">
          <b-form-group label="Fiyat Hesaplama Türü">
            <v-select
              v-model="form.variant_fiyat_turu"
              :options="fiyatTuru"
              :reduce="(fiyat) => fiyat.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select select-size-lg d-block"
              style="min-width: 170px"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="form.variant_fiyat_turu == 'net_fiyat'">
          <b-alert show variant="info" class="text-center rounded-0 p-2">
            Fiyat hesaplama türü ' Net Fiyat ' olarak seçilirse Ürün Satış Fiyatı geçersiz kalir. Variant Bölümünde eklenen Fiyat üzerinden
            hesaplama yapılır.
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" v-for="(variant, index) in form.variant_senecekleri" :key="index">
          <UrunVariantItem :variant="variant" :index="index" :form="form" :variantlar="variantlar" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
              <i class="fad fa-save" />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import UrunVariantItem from './UrunVariantItem.vue';
export default defineComponent({
  components: {
    vSelect,
    UrunVariantItem,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { form } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.variantlar = computed(() => store.getters.getVariantGruplari);
    expo.kampanyalar = computed(() => store.getters.getKampanyalar);

    expo.fiyatTuru = ref([
      {
        value: 'net_fiyat',
        title: 'Net Fiyat',
      },
      {
        value: 'fiyat_fark',
        title: 'Fiyat Farkı',
      },
    ]);

    expo.createVariant = (event) => {
      event.map((el) => {
        const varmi = form.value.variant_senecekleri.find((x) => x.variant_k_no == el);
        if (!varmi) {
          form.value.variant_senecekleri.push({
            variant_k_no: el,
            name: false,
            tipi: 'ozellik',
            secenekler: [],
          });
        }
      });
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
