<template>
  <b-tabs content-class="m-3">
    <b-tab title="Bilgiler" active>
      <Bilgiler :form="form" @form="form = $event" @onSubmit="onSubmit" />
    </b-tab>
    <b-tab title="Adresler">
      <Adresler
        :form="form"
        @form="form = $event"
        @uyeUpdate="handlerUpdateAdres($event)"
        @uyeAdresSil="handlerUyeAdresSil($event)"
        @onSubmit="onSubmit"
      />
    </b-tab>
    <b-tab title="Kuponlar">
      <kuponlar :form="form" @form="form = $event" />
    </b-tab>
    <b-tab title="Hareketleri"> Hareketler </b-tab>
  </b-tabs>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch, beforeRouteLeave } from '@vue/composition-api';
import Bilgiler from './component/Bilgiler.vue';
import store from '@/store';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import Swal from 'sweetalert2';
import Adresler from './component/Adresler.vue';
import Kuponlar from './component/Kuponlar.vue';
export default defineComponent({
  components: {
    Bilgiler,
    Adresler,
    Kuponlar,
  },
  setup(_, context) {
    const expo = {};
    const { route } = useRouter();
    const toast = useToast();
    const routeChange = computed(() => route.value);

    expo.form = ref({
      k_no: null,
      ad: null,
      soyad: null,
      e_mail: null,
      sifre: null,
      telefon: null,
      adresler: [],
      uye_grup_k_no: null,
      hakkimda: null,
      sartlar_kosullar: true,
      statu: true,
    });

    const resetForm = () => {
      expo.form.value = {
        k_no: null,
        ad: '',
        soyad: '',
        e_mail: '',
        sifre: '',
        telefon: '',
        adresler: [],
        kuponlar: [],
        uye_grup_k_no: null,
        hakkimda: null,
        sartlar_kosullar: true,
        statu: true,
      };
    };

    onMounted(async () => {
      context.emit('show', true);
      // await handlerFetchAllData();
      await handlerUpdated();
      context.emit('show', false);
    });

    const handlerUpdated = async () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        const uyeler = store.getters.getUyeler;
        let uye = {};
        if (uyeler.length > 0) {
          uye = uyeler.find((x) => x.k_no === k_no);
          expo.form.value = { ...uye };
        } else {
          await store.dispatch('uyeFindOne', k_no).then((res) => {
            expo.form.value = { ...res };
          });
        }
      }
      context.emit('backTo', true);
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      const baseUrl = expo.form.value.k_no == null ? 'uyeEkle' : 'uyeGuncelle';
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(!expo.form.value.k_no ? 'Ekleme Başarılı' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerUpdateAdres = (event) => {
      const index = expo.form.value.adresler.findIndex((x) => x.k_no == event.k_no);
      if (index == -1) {
        expo.form.value.adresler.push(event);
      } else {
        expo.form.value.adresler[index] = event;
      }
    };

    expo.handlerUyeAdresSil = (k_no) => {
      const index = expo.form.value.adresler.findIndex((x) => x.k_no == k_no);
      if (index != -1) {
        expo.form.value.adresler.splice(index, 1);
      }
    };

    // beforeRouteLeave((to, from) => {
    //   console.log(to);
    // });
    watch(routeChange, (val) => {
      if (Object.keys(val.params).length == 0) {
        resetForm();
      }
    });

    return { ...expo };
  },

  beforeRouteLeave(to, from, next) {
    if (this.form.k_no == null) {
      if (
        this.form.ad.length > 0 ||
        this.form.soyad.length > 0 ||
        this.form.e_mail.length > 0 ||
        this.form.sifre.length > 0 ||
        this.form.telefon.length > 0
      ) {
        Swal.fire({
          title: 'Uyarı',
          text: 'Kaydedilmemiş veri var. Çıkmak istediğinizden eminmisiniz ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet',
          cancelButtonText: 'Hayır',
        }).then((result) => {
          if (result.isConfirmed == true) {
            next();
          } else {
            this.$emit('backTo', true);
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
});
</script>

<style lang="scss" scoped></style>
