<template>
  <div>
    <b-row>
      <b-col cols="12" md="6" v-for="(item, i) in uyeKuponlar" :key="i">
        <b-card no-body class="p-3 rounded-0 border" :class="item.statu ? 'border-success' : 'border-danger'">
          <div class="d-flex justify-content-between">
            <h5 class="p-0 m-0">{{ item.kupon }}</h5>
            <b-button-group>
              <b-button size="sm" :variant="item.statu ? 'success' : 'danger'">
                {{ item.statu ? 'Kullanılmadı' : 'Kullanıldı.' }}
              </b-button>
              <b-button variant="warning" @click="handlerKuponGuncelle(item)">
                <i class="fad fa-pencil"></i>
              </b-button>
              <b-button variant="danger" @click="handlerRemove(item.k_no)">
                <i class="fad fa-trash"></i>
              </b-button>
            </b-button-group>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" lg="6">
        <div class="d-flex">
          <b-button squared block size="lg" variant="primary" class="mr-2" @click="handlerKuponEkle">
            <i class="fad fa-save pr-2"></i>
            <span class="align-middle">YENİ EKLE</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <KuponEkle
      :openModal="openModal"
      :updateData="updateData"
      :uye_k_no="form.k_no"
      :kuponlar="kuponlar"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import KuponEkle from './KuponEkle.vue';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: {
    KuponEkle,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.kuponlar = ref([]);
    expo.uyeKuponlar = ref([]);
    const { form } = toRefs(props);
    const handlerFetchAllData = async () => {
      await store.dispatch('kuponListele', { statu: true });
      await store.dispatch('uyeKuponListele', { uye_k_no: form.value.k_no });
      expo.kuponlar.value = store.getters.getKuponlar;
      expo.uyeKuponlar.value = store.getters.getUyeKuponlar;
    };

    handlerFetchAllData();

    expo.handlerKuponEkle = () => {
      expo.updateData.value = {};
      expo.openModal.value = true;
    };

    expo.handlerKuponGuncelle = (event) => {
      expo.updateData.value = event;
      expo.openModal.value = true;
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('uyeKuponSil', k_no).then((res) => {
            toast.error('Silme Başarılı', { position: 'bottom-left' });
          });
        }
      });
    };

    watch(expo.closeModal, (val) => {
      if (val) {
        expo.openModal.value = false;
        expo.closeModal.value = false;
        expo.uyeKuponlar.value = store.getters.getUyeKuponlar;
      }
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
