<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <b-card class="rounded-0 h-100">
            <b-row>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Satış Yöntemi Belirle">
                  <v-select
                    v-model="form.satis_yontemi"
                    :options="SatisYontemi"
                    :reduce="(tip) => tip.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                    style="min-width: 150px"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" :label="`Satışa ${form.satis_durum ? 'Açık' : 'Kapalı'}`">
                  <b-form-checkbox class="float-right" :checked="form.satis_durum" v-model="form.satis_durum" switch size="lg" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Kur Türü">
                  <b-form-input size="lg" class="rounded-0 text-right" ref="kur_turu" v-model="form.kur_turu" disabled />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Kdv Oranı">
                  <b-input-group>
                    <b-input-group-append>
                      <v-select
                        v-model="form.kdv_turu"
                        :options="kdvTuru"
                        :reduce="(kdv) => kdv.value"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="min-width: 180px"
                      />
                    </b-input-group-append>
                    <b-form-input size="lg" class="rounded-0 text-right" ref="baslik" v-model="form.kdv_oran" />
                    <b-input-group-prepend is-text class="">
                      <span class="px-2" style="font-size: 16px"> % </span>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Ödeme Tipi">
                  <b-input-group>
                    <b-input-group-append>
                      <v-select
                        v-model="form.odeme_tipi"
                        :options="odemeTipi"
                        :reduce="(odeme) => odeme.value"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="min-width: 180px"
                      />
                    </b-input-group-append>
                    <b-form-input
                      size="lg"
                      class="rounded-0 text-right"
                      ref="baslik"
                      v-model="form.odeme_tipi_oran"
                      :disabled="form.odeme_tipi == 'hepsi'"
                    />
                    <b-input-group-prepend is-text class="">
                      <span class="px-2" style="font-size: 16px"> % </span>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" v-if="form.urun_tipi == 'tur'">
          <b-card no-body class="rounded-0 p-2 h-100">
            <b-row>
              <b-col cols="12">
                <h6 class="mt-2">Turun olduğu aktif tarihlerini belirtin.</h6>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="2" label="Günler">
                  <b-form-checkbox-group size="lg" v-model="form.aktif_gunler" :options="gunler" class="demo-inline-spacing" />
                </b-form-group>
                <b-form-group class="pt-3" label-cols="2" label="Aylar">
                  <b-form-checkbox-group size="lg" v-model="form.aktif_aylar" :options="aylar" class="demo-inline-spacing" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group class="pt-2" label="Seans Saatleri">
                  <b-form-tags size="lg" class="rounded-0" v-model="form.seans_saatleri" placeholder="12:00 gibi" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="pt-2"
                  :label="form.fiyat_kisi_surucu == 'kisi' ? 'Yaş Aralığı ( Yetişkin | Çocuk | Bebek)' : 'Yaş Aralığı ( Sürücü | Yolcu)'"
                >
                  <b-input-group>
                    <b-input-group-append>
                      <b-form-input
                        v-mask="'##-##'"
                        class="rounded-0"
                        v-model="form.yas.yetiskin"
                        placeholder="Yetişkin"
                        v-b-tooltip.hover.top="form.fiyat_kisi_surucu == 'kisi' ? 'Yetişkin Yaş' : 'Sürücü Yaş'"
                        :style="form.fiyat_kisi_surucu == 'arac' && 'width: 300px'"
                      />
                    </b-input-group-append>
                    <b-form-input
                      v-mask="'##-##'"
                      class="rounded-0"
                      v-model="form.yas.cocuk"
                      placeholder="Çocuk"
                      v-b-tooltip.hover.top="form.fiyat_kisi_surucu == 'kisi' ? 'Çocuk Yaş' : 'Yolcu Yaş'"
                    />
                    <b-input-group-prepend v-if="form.fiyat_kisi_surucu == 'kisi'">
                      <b-form-input
                        v-mask="'##-##'"
                        class="rounded-0"
                        v-model="form.yas.bebek"
                        placeholder="Bebek"
                        v-b-tooltip.hover.top="'Bebek Yaş'"
                      />
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" v-else-if="form.urun_tipi == 'villa' || form.urun_tipi == 'yat'">
          <b-card no-body class="rounded-0 p-2 h-100">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-cols="5"
                  class="pt-2"
                  label="Minimum Konaklama"
                  description="Minimum Konaklama Gün Cinsinden Hesaplanır."
                >
                  <b-form-input size="lg" class="rounded-0 text-right" type="number" v-model="form.minimum_konaklama" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-cols="5"
                  label="Depozito Tutarı"
                  description="Girişte Seçilirse Misafir Villaya Girişte Ödeme Yapar,Ödeme de Seçilirse Misafir Rezervasyon Esnasında Yapar."
                >
                  <b-input-group>
                    <b-input-group-append>
                      <v-select
                        v-model="form.depozito_tahsil_durum"
                        :options="depozitoTahsilDurum"
                        :reduce="(odeme) => odeme.value"
                        label="title"
                        :clearable="false"
                        class="invoice-filter-select d-block select-size-lg"
                        style="min-width: 180px"
                      />
                    </b-input-group-append>
                    <b-form-input size="lg" class="rounded-0 text-right" v-model="form.depozito" />
                    <b-input-group-prepend is-text class="">
                      <span class="px-2" style="font-size: 16px">
                        {{ form.kur_turu }}
                      </span>
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group class="pt-2" label="Yaş Aralığı ( Yetişkin | Çocuk | Bebek)">
                  <b-input-group>
                    <b-input-group-append>
                      <b-form-input v-mask="'##-##'" class="rounded-0" v-model="form.yas.yetiskin" placeholder="Yetişkin" />
                    </b-input-group-append>
                    <b-form-input v-mask="'##-##'" class="rounded-0" v-model="form.yas.cocuk" placeholder="Çocuk" />
                    <b-input-group-prepend>
                      <b-form-input v-mask="'##-##'" class="rounded-0" v-model="form.yas.bebek" placeholder="Bebek" />
                    </b-input-group-prepend>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" v-else-if="form.urun_tipi == 'arac_kira'">
          <b-card no-body class="rounded-0 p-2 h-100">
            <b-row>
              <b-col cols="12">
                <b-form-group label-cols="5" class="pt-2" label="Minimum Gün">
                  <b-form-input size="lg" class="rounded-0 text-right" type="number" v-model="form.minimum_konaklama" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group
            label="Ücretledirme Türü"
            description="Dönemsel fiyat hesaplama iki tarih arası fiyatlandırma yapar, Bölgesel fiyatlandırma ise seçillen bölgeye göre fiyat hesaplar."
          >
            <v-select
              v-model="form.fiyat_turu"
              :options="fiyatlandirmaTuru"
              :reduce="(tip) => tip.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block"
              style="min-width: 150px"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" v-if="form.urun_tipi == 'tur'">
          <b-form-group
            label="Fiyat Tekil | Grup"
            description="Tekil Fiyatlandırma seçilen kişiye göre fiyat hesaplar. Grup fiyatlandırma seçilirse en çok kapasite kişi sayısına göre fiyat sabit olur, artı kişi içinde ekstra fiyat hesaplanır."
          >
            <v-select
              v-model="form.fiyatlandirma"
              :options="fiyatlandirmaTipi"
              :reduce="(tip) => tip.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block"
              style="min-width: 150px"
              v-if="form.urun_tipi == 'tur'"
              @input="handlerFiyatlandirmaChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" v-if="form.urun_tipi == 'tur'">
          <b-form-group
            label="Kişi | Araç Fiyat"
            description="Kişi Seçilirse (Yetişkin,Çocuk,Bebek) fiyatlandırması yapılır. Araç Seçilirse (Sürücü ve Yolcu Fiyatı Belirlenir.)"
          >
            <v-select
              v-model="form.fiyat_kisi_surucu"
              :options="fiyatKisiSurucu"
              :reduce="(tip) => tip.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block"
              style="min-width: 150px"
              :disabled="form.fiyatlandirma == 'grup'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white">
              <div class="d-flex justify-content-between align-items-center">
                <strong> <i class="fad fa-money-bill"></i> Satış Fiyat Politikası </strong>
                <div class="d-flex">
                  <v-select
                    v-model="form.fiyat_politikasi"
                    :options="fiyatPolitikalari"
                    :reduce="(tip) => tip.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block"
                    style="min-width: 150px"
                    @input="handlerFiyatlandirmaChange($event)"
                  />
                  <b-button squared size="sm" variant="info" v-if="form.fiyat_politikasi == 'standart'" @click="handlerUrunSatisFiyatEkle">
                    <i class="fad fa-plus"> </i> {{ form.fiyat_turu == 'donemsel' ? 'Dönem Ekle' : 'Bölge Ekle' }}
                  </b-button>

                  <b-button squared size="sm" variant="success" v-else @click="handlerUrunSatisFiyatEkle">
                    <i class="fad fa-plus"></i> Periyot Ekle
                  </b-button>
                </div>
              </div>
            </b-card-header>
            <b-card-text>
              <template v-if="form.fiyat_politikasi == 'standart'">
                <b-card no-body class="rounded-0 m-2">
                  <table class="table table-striped table-sm">
                    <thead class="font-weight-bold">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Tarih Aralığı</th>
                        <th scope="col" class="text-right">
                          Fiyat
                          <b v-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'tekil'">
                            <template v-if="form.fiyat_kisi_surucu == 'kisi'"> ( Yetişkin | Çocuk | Bebek) </template>
                            <template v-else> ( Sürücü | Yolcu) </template>
                          </b>
                          <b v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'grup'">
                            ( Kapasite: En Az ve Çok, Fiyat, Artı Kişi Fiyat )</b
                          >
                        </th>
                        <th scope="col" class="text-right">İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      <UrunSatisFiyatItem
                        v-for="(item, i) in form.satis_fiyat"
                        :key="i"
                        :number="i"
                        :item="item"
                        :form="form"
                        :defaultDil="defaultDil"
                        @remove="handlerRemoveFiyat($event)"
                      />
                    </tbody>
                  </table>
                </b-card>
              </template>
              <template v-else>
                <b-alert variant="warning" show class="text-center rounded-0">
                  Varsayılan olarak bir seçim yapmanız gerekmektedir. Herhangi bir seçim yapmazsanız, ilk program otomatik varsayılan olarak
                  belirlenecektir.
                </b-alert>
                <draggable v-model="form.satis_fiyat" tag="ul" class="list-group" handle=".draggable-sort-item">
                  <UrunSatisFiyatPeriyotItem
                    v-for="(fiyat, index) in form.satis_fiyat"
                    :key="index"
                    :index="index"
                    :item="fiyat"
                    :form="form"
                  />
                </draggable>
              </template>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
              <i class="fad fa-save" />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import draggable from 'vuedraggable';
import { ref, defineComponent, computed, toRefs } from '@vue/composition-api';
import UrunSatisFiyatItem from './UrunSatisFiyatItem.vue';
import UrunSatisFiyatPeriyotItem from './UrunSatisFiyatPeriyotItem.vue';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: { vSelect, draggable, UrunSatisFiyatItem, UrunSatisFiyatPeriyotItem },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    const { form } = toRefs(props);

    expo.SatisYontemi = ref([
      { value: 'direk', title: 'Direk Satış' },
      { value: 'sorgula', title: 'Müsaitlik Sorgula' },
    ]);

    expo.kdvTuru = ref([
      { value: 'haric', title: 'Hariç' },
      { value: 'dahil', title: 'Dahil' },
    ]);

    expo.odemeTipi = ref([
      { value: 'hepsi', title: 'Hepsi' },
      { value: 'on_odeme', title: 'Ön Ödemeli' },
    ]);

    expo.depozitoTahsilDurum = ref([
      { value: 'giriste', title: 'Girişte' },
      { value: 'odemede', title: 'Ödemede' },
    ]);

    expo.gunler = ref([
      { text: 'Pzt', value: 'pazartesi' },
      { text: 'Sal', value: 'sali' },
      { text: 'Çar', value: 'carsamba' },
      { text: 'Per', value: 'persembe' },
      { text: 'Cum', value: 'cuma' },
      { text: 'Cmt', value: 'cumartesi' },
      { text: 'Paz', value: 'pazar' },
    ]);
    expo.aylar = ref([
      { text: 'Oca', value: 'ocak' },
      { text: 'Şub', value: 'subat' },
      { text: 'Mar', value: 'mart' },
      { text: 'Nis', value: 'nisan' },
      { text: 'May', value: 'mayis' },
      { text: 'Haz', value: 'haziran' },
      { text: 'Tem', value: 'temmuz' },
      { text: 'Ağu', value: 'agustos' },
      { text: 'Eyl', value: 'eylul' },
      { text: 'Eki', value: 'ekim' },
      { text: 'Kas', value: 'kasim' },
      { text: 'Ara', value: 'aralik' },
    ]);

    expo.fiyatlandirmaTuru = ref([
      { title: 'Dönemsel', value: 'donemsel' },
      { title: 'Bölgesel', value: 'bolgesel' },
    ]);
    expo.fiyatlandirmaTipi = ref([
      { title: 'Tekil Fiyat', value: 'tekil' },
      { title: 'Grup Fiyat', value: 'grup' },
    ]);

    expo.fiyatKisiSurucu = ref([
      { title: 'Kişiye Göre Fiyat', value: 'kisi' },
      { title: 'Araç Başı Fiyat', value: 'arac' },
    ]);

    expo.fiyatPolitikalari = ref([
      { title: 'Standart Fiyat', value: 'standart' },
      { title: 'Periyot Fiyat', value: 'periyot' },
    ]);

    expo.handlerUrunSatisFiyatEkle = () => {
      if (form.value.fiyat_politikasi == 'standart') {
        if (form.value.urun_tipi == 'tur') {
          if (form.value.fiyatlandirma == 'tekil') {
            form.value.satis_fiyat.push({
              bolge: null,
              bas_tarih: null,
              bit_tarih: null,
              fiyat: { yetiskin: 0, cocuk: 0, bebek: 0 },
              maliyet: { yetiskin: 0, cocuk: 0, bebek: 0 },
            });
          } else {
            form.value.satis_fiyat.push({
              bolge: null,
              bas_tarih: null,
              bit_tarih: null,
              fiyat: { en_az: 1, en_cok: 0, fiyat: 0, arti_fiyat: 0 },
              maliyet: { fiyat: 0, arti_fiyat: 0 },
            });
          }
        } else {
          form.value.satis_fiyat.push({
            bolge: null,
            bas_tarih: null,
            bit_tarih: null,
            fiyat: 0,
            maliyet: 0,
          });
        }
      } else {
        let baslik = {};
        langs.forEach((dil) => {
          baslik[dil.lang] = '';
        });
        if (form.value.urun_tipi == 'tur') {
          form.value.satis_fiyat.push({
            baslik,
            default: false,
            fiyat: [
              {
                bolge: null,
                bas_tarih: null,
                bit_tarih: null,
                ...(form.value.fiyatlandirma == 'tekil' && {
                  fiyat: { yetiskin: 0, cocuk: 0, bebek: 0 },
                  maliyet: { yetiskin: 0, cocuk: 0, bebek: 0 },
                }),
                ...(form.value.fiyatlandirma == 'grup' && {
                  fiyat: { en_az: 1, en_cok: 0, fiyat: 0, arti_fiyat: 0 },
                  maliyet: { fiyat: 0, arti_fiyat: 0 },
                }),
              },
            ],
          });
        } else {
          form.value.satis_fiyat.push({
            baslik,
            default: false,
            fiyat: [
              {
                bolge: null,
                bas_tarih: null,
                bit_tarih: null,
                fiyat: 0,
                maliyet: 0,
              },
            ],
          });
        }
      }
    };

    expo.handlerFiyatlandirmaChange = () => {
      if (form.value.satis_fiyat.length) {
        Swal.fire({
          title: 'Uyarı',
          text: `Mevcut fiyatlandirmalar silinecektir. Değişiklik yapmak istiyormusunuz`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet',
          cancelButtonText: 'Hayır',
        }).then((result) => {
          if (result.isConfirmed == true) {
            form.value.satis_fiyat = [];
          }
        });
      }
    };

    expo.handlerRemoveFiyat = (event) => {
      form.value.satis_fiyat.splice(event, 1);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
