<template>
  <div>
    <b-row>
      <b-col cols="12" v-if="!form.adresler.length">
        <b-alert variant="warning" show class="text-center">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Henüz Kayıt Bulunmamaktadır.</p>
        </b-alert>
      </b-col>
      <b-col cols="12" md="6" v-for="(item, i) in form.adresler" :key="i" v-else>
        <AdresItem :item="item" @uyeAdresSil="$emit('uyeAdresSil', $event)" @handlerUpdate="handlerUpdate($event)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" lg="6">
        <div class="d-flex">
          <b-button squared block size="lg" variant="primary" class="mr-2" @click="handlerAdresEkle">
            <i class="fad fa-save pr-2"></i>
            <span class="align-middle">YENİ EKLE</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <AdresEkle
      :openModal="openModal"
      :updateData="updateData"
      :uye_k_no="form.k_no"
      @uyeUpdate="$emit('uyeUpdate', $event)"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch, toRefs } from '@vue/composition-api';
import AdresItem from './AdresItem.vue';
import AdresEkle from './AdresEkle.vue';

export default defineComponent({
  components: { AdresItem, AdresEkle },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    const { form } = toRefs(props);

    expo.handlerAdresEkle = () => {
      expo.updateData.value = {};
      expo.openModal.value = true;
    };

    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
      expo.openModal.value = true;
    };

    watch(expo.closeModal, (val) => {
      if (val) {
        expo.openModal.value = false;
        expo.closeModal.value = false;
      }
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
