<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <b-card class="rounded-0">
          <b-row>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Banner Alanını Göster</label>
                <b-form-checkbox :checked="form.ayar.banner" v-model="form.ayar.banner" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Bread Crumbs Alanı Göster</label>
                <b-form-checkbox :checked="form.ayar.bread_crumbs" v-model="form.ayar.bread_crumbs" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Video Gösterimi</label>
                <b-form-checkbox :checked="form.ayar.video_gosterim" v-model="form.ayar.video_gosterim" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Benzer Sayfaları Göster</label>
                <b-form-checkbox :checked="form.ayar.benzer_sayfalar" v-model="form.ayar.benzer_sayfalar" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Misafir Yorum Göster</label>
                <b-form-checkbox :checked="form.ayar.misafir_yorumlari" v-model="form.ayar.misafir_yorumlari" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Ürünlerden Bazıları Göster</label>
                <b-form-checkbox
                  :checked="form.ayar.urunlerden_bazilari"
                  v-model="form.ayar.urunlerden_bazilari"
                  switch
                  size="lg"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Abone Ol Göster</label>
                <b-form-checkbox :checked="form.ayar.abone_ol" v-model="form.ayar.abone_ol" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu" class="h6">Özel Teklif Formu Göster</label>
                <b-form-checkbox :checked="form.ayar.form" v-model="form.ayar.form" switch size="lg" />
              </div>
            </b-col>
            <b-col cols="12">
              <b-form-group label-cols="5" label="Sayfa Format">
                <v-select
                  v-model="form.ayar.sayfa_format"
                  :options="sayfaFormat"
                  :reduce="(sayfa) => sayfa.id"
                  label="title"
                  :clearable="false"
                  class="invoice-filter-select d-block select-size-lg"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" v-if="form.ayar.banner">
        <b-card class="rounded-0">
          <b-form-group label="Banner Alt Görünüm" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="form.ayar.banner_format"
              :options="bannerOptions"
              :aria-describedby="ariaDescribedby"
              name="plain-inline"
              plain
            />
          </b-form-group>
          <div class="banner">
            <img :src="showImage(form.gorseller)" class="banner-img" alt="" />
            <h5 class="banner-title">Lorem ipsum</h5>
            <p class="banner-desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis facilis ipsam cum.</p>
            <svg class="banner-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none">
              <polygon
                v-if="form.ayar.banner_format == 'sol' || form.ayar.banner_format == 'duz' || form.ayar.banner_format == 'orta'"
                points="100 10 100 0 0 10"
              />
              <polygon v-if="form.ayar.banner_format == 'duz'" points="100 0 0 0 0 10" />
              <polygon
                v-if="form.ayar.banner_format == 'sag' || form.ayar.banner_format == 'duz' || form.ayar.banner_format == 'orta'"
                points="100 10 0 0 0 10"
              />
            </svg>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pt-1">
      <b-col cols="12" md="6" lg="6">
        <b-button squared block type="submit" variant="primary" @click="$emit('onSubmit', true)">
          <i class="fad fa-save" />
          <span class="align-middle">Güncelle</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, onUpdated } from '@vue/composition-api';
import store from '@/store';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.bannerOptions = ref([
      { text: 'Sağa Yatık', value: 'sag' },
      { text: 'Sola Yatık', value: 'sol' },
      { text: 'Orta', value: 'orta' },
      { text: 'Düz', value: 'duz' },
    ]);

    expo.options = ref([
      { text: 'Sağa Yatık', value: 'sag' },
      { text: 'Sola Yatık', value: 'sol' },
      { text: 'Orta', value: 'orta' },
      { text: 'Düz', value: 'duz' },
    ]);

    expo.sayfaFormat = ref([
      { id: 'yazi', title: 'Sadece Yazı' },
      { id: 'yazi_resim', title: 'Yazı ve Resim' },
      { id: 'yazi_galeri', title: 'Yazı ve Galeri' },
    ]);

    expo.showImage = computed(() => {
      return (val) => {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
        if (Object.keys(val).length > 0) {
          const selectImg = val.find((x) => x.banner == true);
          if (selectImg != undefined) {
            return `${base_url}uploads/${fileName}/sayfa/${selectImg.gorsel}`;
          } else {
            return require('@/assets/images/banner/banner.jpg');
          }
        } else {
          return require('@/assets/images/banner/banner.jpg');
        }
      };
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 10px;

  .banner-title {
    position: absolute;
    top: 20px;
    left: 10px;
  }
  .banner-desc {
    position: absolute;
    top: 45px;
    left: 10px;
  }
  .banner-img {
    // max-width: 100%;
    width: 650px;
    height: 200px;
  }

  .banner-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 102%;
    height: 50px;
    z-index: 2;
    fill: #fff;
  }
}
</style>
