<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="form">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="7">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Başlık">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="baslik"
                      v-model="form.icerik[defaultDil].baslik"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerBaslikChange"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Açıklama" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Açıklama" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                    <ckeditor
                      :editor="editor"
                      v-model="form.icerik[defaultDil].aciklama"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      style="height: 400px !importat"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- <b-col cols="12">
                <b-form-group label="Açıklama 2">
                  <ckeditor :editor="editor" v-model="form.icerik[defaultDil].aciklama_2" style="height: 400px !importat" />
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider
                  name="Ön Açıklama (Description)"
                  :rules="{ required: true, max: 300 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    :label="`Ön Açıklama (Description) ${onAciklamaLenght} / 300`"
                    description="Ön Açıklama 160 karakteri geçmemelidir"
                  >
                    <b-form-textarea
                      class="rounded-0"
                      ref="on_aciklama"
                      v-model="form.icerik[defaultDil].description"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerOnAcklamaChange"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Anahtar Kelimeler (Keywords)">
                  <b-form-tags size="lg" v-model="form.icerik[defaultDil].keywords" input-id="tags-basic" class="rounded-0" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Slug (Seflink)"
                  description="Bu link yapısı bir kere oluşturulacaktır. Düzenleme yaptığınız takdirde Google,Yandex vb siteler indexlemeyi ortalama 1 ay için de tekrardan bu sayfayı indexleme alacaktır."
                >
                  <b-input-group>
                    <b-form-input size="lg" class="rounded-0" v-model="form.icerik[defaultDil].slug" :disabled="!slug_duzenle" />
                    <b-input-group-append v-if="form.k_no">
                      <b-button squared variant="outline-success" @click="slug_duzenle = !slug_duzenle">
                        <i class="fad fa-pencil"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Video">
                  <b-form-input size="lg" class="rounded-0" ref="video" v-model="form.video" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Rezervasyon Bilgi Notu"
                  description="Rezervasyon formunun üst kısmında cıkacaktır. Rezervasyon yaparken gerekli bilgi aktarımı için kullanabilirsiniz."
                >
                  <b-form-textarea size="lg" class="rounded-0" v-model="form.icerik[defaultDil].rez_ozel_not" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="5">
          <b-card class="rounded-0" v-sticky="true" sticky-offset="{top: 100}">
            <b-row>
              <b-col cols="12" md="6" v-show="diller.length != 1">
                <b-form-group label="Dil">
                  <v-select
                    v-model="defaultDil"
                    :options="diller"
                    :reduce="(diller) => diller.lang"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                    :disabled="!form.k_no"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Ürün Tipi">
                  <v-select
                    v-model="form.urun_tipi"
                    :options="urunTipleri"
                    :reduce="(tip) => tip.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="form.urun_tipi == 'villa' || form.urun_tipi == 'yat'">
              <b-col cols="12" md="4">
                <b-form-group label="Oda Sayısı">
                  <b-form-input v-model="form.villa_ozellik.oda" size="lg" type="number" class="rounded-0" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group label="Kişi Sayısı">
                  <b-form-input v-model="form.villa_ozellik.kisi" size="lg" type="number" class="rounded-0" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group label="Banyo Sayısı">
                  <b-form-input v-model="form.villa_ozellik.banyo" size="lg" type="number" class="rounded-0" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Ürün Kodu" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Ürün Kodu">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="baslik"
                      v-model="form.urun_kodu"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" v-if="kategoriler.length > 0">
                <validation-provider name="Kategori" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Kategori" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                    <treeselect
                      :options="kategoriler"
                      v-model="form.kategori_k_no"
                      :normalizer="normalizer"
                      :flat="true"
                      :sort-value-by="sortValueBy"
                      :default-expand-level="1"
                      noChildrenText="Alt Öge Yok."
                      placeholder="Lütfen Kategori Seçiniz"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      :class="{ 'is-invalid border-danger': !!validationContext.errors[0] }"
                      class="rounded-0"
                      :multiple="true"
                      :max-height="200"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" v-if="bolgeler.length > 0">
                <validation-provider
                  :name="form.urun_tipi == 'tur' ? 'Hizmet Verilen Lokasyonlar' : 'Lokasyon'"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    :label="form.urun_tipi == 'tur' ? 'Hizmet Verilen Lokasyonlar' : 'Lokasyon'"
                    :class="{ 'is-invalid': !!validationContext.errors[0] }"
                  >
                    <treeselect
                      :options="bolgeler"
                      v-model="form.bolge_k_no"
                      :normalizer="normalizer"
                      :flat="true"
                      :sort-value-by="sortValueBy"
                      :default-expand-level="1"
                      noChildrenText="Alt Öge Yok."
                      placeholder="Lütfen Lokasyon Seçiniz"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      :class="{ 'is-invalid border-danger': !!validationContext.errors[0] }"
                      class="rounded-0"
                      :multiple="true"
                      :max-height="200"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Yayın Durumu">
                  <v-select
                    v-model="form.statu"
                    :options="statuler"
                    :reduce="(statu) => statu.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Vitrin Gösterim">
                  <v-select
                    multiple
                    v-model="form.vitrin_gosterim"
                    :options="vitringosterim"
                    :reduce="(durum) => durum.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center py-3">
                  <label for="statu" class="h6">Seo Optimize</label>
                  <b-form-checkbox :checked="form.seo" v-model="form.seo" switch size="lg" />
                </div>
              </b-col>
              <b-col cols="12">
                <b-button
                  size="lg"
                  :variant="form.k_no == null ? 'success' : 'warning'"
                  type="submit"
                  block
                  class="rounded-0 mr-1"
                >
                  <i class="fad fa-save" />
                  {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { computed, defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useToast } from 'vue-toastification/composition';
import slugify from 'slugify';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default defineComponent({
  components: {
    vSelect,
    Treeselect,
  },
  props: {
    diller: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    const { form } = toRefs(props);

    expo.sortValueBy = ref('INDEX');

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.onAciklamaLenght = ref(0);
    expo.editor = ref(ClassicEditor);
    expo.kategoriler = computed(() => store.getters.getKategoriler.filter((x) => x.turu == 'urun'));
    expo.bolgeler = computed(() => store.getters.getBolgeler);

    expo.slug_duzenle = ref(false);

    expo.normalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.icerik != undefined && node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.urunTipleri = ref([
      { value: 'villa', title: 'Villa' },
      { value: 'yat', title: 'Yat' },
      { value: 'tur', title: 'Tur' },
      { value: 'arac_kira', title: 'Araç' },
      { value: 'emlak', title: 'Emlak' },
    ]);

    expo.statuler = ref([
      { value: 'yayinda', title: 'Yayında' },
      { value: 'yayin_disi', title: 'Yayın Dışı' },
      { value: 'taslak', title: 'Taslak' },
    ]);

    expo.vitringosterim = ref([
      { value: 'vitrin_1', title: 'Vitrin 1' },
      { value: 'vitrin_2', title: 'Vitrin 2' },
      { value: 'yeni_urun', title: 'Yeni Ürün' },
      { value: 'firsat_urun', title: 'Fırsat Ürünü' },
      { value: 'cok_satan', title: 'Çok Satan' },
      { value: 'begenilen', title: 'Beğenilen' },
      { value: 'indirimde', title: 'İndirimde' },
      { value: 'ozel_urun', title: 'Özel Ürün' },
    ]);

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      if (!form.value.k_no || expo.slug_duzenle.value) form.value.icerik[expo.defaultDil.value].slug = slug;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
