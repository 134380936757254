<template>
  <b-tabs class="p-2">
    <b-tab title="İÇERİK">
      <UrunIcerik :diller="diller" :form="form" @form="form = $event" @onSubmit="onSubmit" />
    </b-tab>
    <b-tab title="FOTOGRAĞLAR" :disabled="!form.k_no">
      <UrunFotograflar :form="form" @form="form = $event" @show="$emit('show', $event)" />
    </b-tab>
    <b-tab title="SATIŞ & FİYAT" :disabled="!form.k_no">
      <UrunSatisFiyat :form="form" @onSubmit="onSubmit" />
    </b-tab>
    <b-tab title="KONUM & EK HİZMET" :disabled="!form.k_no">
      <UrunKonumEkstraHizmet :form="form" @onSubmit="onSubmit" />
    </b-tab>
    <b-tab title="TUR DİĞER BİLGİLER" :disabled="!form.k_no" v-if="form.urun_tipi == 'tur'">
      <UrunTurDigerBilgiler :form="form" @onSubmit="onSubmit" />
    </b-tab>
    <b-tab title="ALIŞ SAATLER" lazy :disabled="!form.k_no" v-if="form.urun_tipi == 'tur'">
      <UrunTurBolgeAlisSaatler :form="form" @onSubmit="onSubmit" />
    </b-tab>
    <b-tab title="ÖZEL ALANLAR" :disabled="!form.k_no">
      <UrunOzelAlanlar :form="form" @onSubmit="onSubmit" @removeOzelAlanlar="removeOzelAlanlar" />
    </b-tab>
    <b-tab title="VARİANT" active :disabled="!form.k_no">
      <UrunVariantlar :form="form" @onSubmit="onSubmit" @removeVariant="removeVariant" />
    </b-tab>
    <b-tab title="KAMPANYA" :disabled="!form.k_no">
      <UrunKampanyalar
        :form="form"
        @onSubmit="onSubmit"
        @createOzelFiyat="createOzelFiyat"
        @removeOzelFiyat="removeOzelFiyat"
        @removeVariant="removeVariant"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';
import UrunIcerik from './component/UrunIcerik.vue';
import UrunFotograflar from './component/UrunFotograflar.vue';
import UrunSatisFiyat from './component/UrunSatisFiyat.vue';
import UrunKonumEkstraHizmet from './component/UrunKonumEkstraHizmet.vue';
import UrunTurDigerBilgiler from './component/UrunTurDigerBilgiler.vue';
import UrunTurBolgeAlisSaatler from './component/UrunTurBolgeAlisSaatler.vue';
import UrunOzelAlanlar from './component/UrunOzelAlanlar.vue';
import UrunVariantlar from './component/UrunVariantlar.vue';
import UrunKampanyalar from './component/UrunKampanyalar.vue';
import { defineComponent, ref, computed, onMounted, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    UrunIcerik,
    UrunFotograflar,
    UrunSatisFiyat,
    UrunKonumEkstraHizmet,
    UrunTurDigerBilgiler,
    UrunTurBolgeAlisSaatler,
    UrunOzelAlanlar,
    UrunVariantlar,
    UrunKampanyalar,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    const { route } = useRouter();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    const routeChange = computed(() => route.value);

    expo.form = ref({
      k_no: null,
      urun_tipi: 'villa',
      villa_ozellik: {
        oda: 0,
        kisi: 0,
        banyo: 0,
      },
      kategori_k_no: [],
      bolge_k_no: [],
      urun_kodu: null,
      icerik: {},
      video_url: null,
      gorseller: [],
      satis_yontemi: 'direk',
      kur_turu: 'TRY',
      kdv_turu: 'dahil', // Haric, Dahil
      kdv_oran: 0,
      odeme_tipi: 'hespi',
      odeme_tipi_oran: 0,
      aktif_gunler: [],
      aktif_aylar: [],
      seans_saatleri: [],
      yas: {
        yetiskin: '00-00',
        cocuk: '00-00',
        bebek: '00-00',
      },
      refakatci_gereklimi: false,
      rehber_sesli_yazili: false,
      rehber_lisanslimi: false,
      rehber_sofor_hizmeti: false,
      rehber_bildigi_diller: [
        {
          diller: 'Türkçe',
          dil: 'turkce',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'Almanca',
          dil: 'almanca',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'İngilizce',
          dil: 'ingilizce',
          sahis: false,
          sesli: false,
          okuma: false,
        },
        {
          diller: 'Rusça',
          dil: 'rusca',
          sahis: false,
          sesli: false,
          okuma: false,
        },
      ],
      asgari_yas: 0,
      maksimum_kisi: 0,
      maksimum_yas: 0,
      toplam_sure: [0, 'saat'],
      bolge_alis: [],
      fiyat_turu: 'donemsel',
      fiyatlandirma: 'tekil',
      fiyat_kisi_arac: 'kisi', //kisi, arac
      fiyat_politikasi: 'standart',
      satis_fiyat: [], //tarih,fiyat
      ozel_satis_fiyat: [],
      depozito_tahsil_turu: 'giriste', //giriste,odemede
      depozito: 0,
      minimum_konaklama: 1,
      konum: null,
      ekstra_hizmet_k_no: [],
      variant_k_no: [],
      variant_senecekleri: [],
      varinat_fiyat_turu: 'fiyat_fark',
      kampanya_k_no: null,
      vitrin: [],
      kolleksiyon: [],
      statu: 'yayinda', //yayinda,taslak,yayin_disi
      seo: true,
    });

    const resetForm = () => {
      expo.form.value = {
        k_no: null,
        urun_tipi: 'villa',
        kategori_k_no: [],
        bolge_k_no: [],
        urun_kodu: null,
        icerik: {},
        video_url: null,
        gorseller: [],
        satis_yontemi: 'direk',
        kur_turu: 'TRY',
        kdv_turu: 'dahil', // Haric, Dahil
        kdv_oran: 0,
        aktif_gunler: [],
        aktif_aylar: [],
        seans_saatleri: [],
        refakatci_gereklimi: false,
        rehber_sesli_yazili: false,
        rehber_lisanslimi: false,
        rehber_sofor_hizmeti: false,
        rehber_bildigi_diller: [
          {
            diller: 'Türkçe',
            dil: 'turkce',
            sahis: false,
            sesli: false,
            okuma: false,
          },
          {
            diller: 'Almanca',
            dil: 'almanca',
            sahis: false,
            sesli: false,
            okuma: false,
          },
          {
            diller: 'İngilizce',
            dil: 'ingilizce',
            sahis: false,
            sesli: false,
            okuma: false,
          },
          {
            diller: 'Rusça',
            dil: 'rusca',
            sahis: false,
            sesli: false,
            okuma: false,
          },
        ],
        asgari_yas: 0,
        maksimum_kisi: 0,
        maksimum_yas: 0,
        toplam_sure: [0, 'saat'],
        bolge_alis: [],
        fiyat_turu: 'donemsel',
        fiyatlandirma: 'tekil',
        fiyat_kisi_arac: 'kisi', //kisi, arac
        satis_fiyat: [], //tarih,fiyat
        ozel_satis_fiyat: [],
        depozito: 0,
        konum: null,
        ekstra_hizmet_k_no: [],
        variant_k_no: [],
        variant_senecekleri: [],
        varinat_fiyat_turu: 'fiyat_fark',
        kampanya_k_no: null,
        vitrin: [],
        kolleksiyon: [],
        statu: 'yayinda', //yayinda,taslak,yayin_disi
      };
    };

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          baslik: '',
          aciklama: '',
          aciklama_2: '',
          description: '',
          keyword: [],
          slug: null,
          rez_ozel_not: '',
        };
      });
      expo.form.value.kur_turu = expo.kurTurleri.value.varsayilan;
    };

    createIcerikDilUret();

    expo.createOzelFiyat = () => {
      const uyeGrupLength = store.getters.getUyeGrupListele.length;
      const ozelFiyatLength = expo.form.value.ozel_satis_fiyat.length;
      if (ozelFiyatLength < uyeGrupLength) {
        expo.form.value.ozel_satis_fiyat.push({ uye_grup_k_no: null, oran: 0 });
      }
    };

    expo.removeOzelFiyat = (index) => {
      expo.form.value.ozel_satis_fiyat.splice(index, 1);
    };

    expo.removeVariant = (index) => {
      expo.form.value.variant_senecekleri.splice(index, 1);
    };

    expo.removeOzelAlanlar = (index) => {
      // expo.form.value.variant_senecekleri.splice(index, 1);
    };

    onMounted(async () => {
      context.emit('show', true);
      await handlerUpdated();
      context.emit('show', false);
    });

    const handlerUpdated = async () => {
      const k_no = route.value.params.k_no;

      if (k_no != null) {
        const urunler = store.getters.getUrunler;
        let urun = {};
        if (urunler.length > 0) {
          urun = urunler.find((x) => x.k_no === k_no);
          expo.form.value = { ...urun };
        } else {
          await store.dispatch('UrunFindOne', k_no).then((res) => {
            expo.form.value = { ...res };
          });
        }

        context.emit('pageTitle', expo.form.value.icerik['tr'].baslik + ' Güncelleniyor');
        context.emit('backTo', true);
      } else {
        context.emit('pageTitle', 'Ürün Ekle');
      }
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      const baseUrl = expo.form.value.k_no == null ? 'UrunEkle' : 'UrunGuncelle';
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            expo.form.value = res.data.data;
            toast.success(!expo.form.value.k_no ? 'Ekleme Başarılı' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    watch(routeChange, (val) => {
      if (Object.keys(val.params).length == 0) {
        resetForm();
        createIcerikDilUret();
      }
    });

    return { ...expo };
  },
  beforeRouteLeave(to, from, next) {
    if (this.form.k_no == null) {
      if (
        this.form.icerik[this.defaultDil].baslik.length > 0 ||
        this.form.icerik[this.defaultDil].aciklama.length > 0 ||
        this.form.icerik[this.defaultDil].description.length > 0 ||
        this.form.icerik[this.defaultDil].keyword.length > 0
      ) {
        Swal.fire({
          title: 'Uyarı',
          text: 'Kaydedilmemiş veri var. Çıkmak istediğinizden eminmisiniz ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet',
          cancelButtonText: 'Hayır',
        }).then((result) => {
          if (result.isConfirmed == true) {
            next();
          } else {
            this.$emit('backTo', true);
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
});
</script>

<style lang="scss" scoped></style>
