<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                v-model="form.ad"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Soyadı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Soyadı">
              <b-form-input
                size="lg"
                class="rounded-0"
                v-model="form.soyad"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="E Mail " :rules="{ required: true, email: true }" v-slot="validationContext">
            <b-form-group label="E Mail ( Kullanıcı Adı )">
              <b-form-input
                size="lg"
                class="rounded-0"
                v-model="form.e_mail"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Şifre" :rules="{ required: !form.k_no }" v-slot="validationContext">
            <b-form-group label="Şifre">
              <b-form-input
                size="lg"
                class="rounded-0"
                v-model="form.sifre"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Telefon">
              <b-form-input
                size="lg"
                class="rounded-0"
                v-model="form.telefon"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Üye Grubu">
            <v-select
              v-model="form.uye_grup_k_no"
              :options="uyeGruplari"
              :reduce="(uye) => uye.k_no"
              label="baslik"
              :clearable="true"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Hakkımda">
            <b-form-textarea size="lg" class="rounded-0" rows="4" v-model="form.hakkimda" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="my-3">
          <div class="d-flex justify-content-between align-items-center">
            <label for="statu">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
        <b-col cols="12" md="6" class="my-3">
          <div class="d-flex justify-content-between align-items-center">
            <label for="statu">Şartlar ve Koşullar</label>
            <b-form-checkbox :checked="form.sartlar_kosullar" v-model="form.sartlar_kosullar" switch size="lg" />
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
            <i class="fad fa-save" />
            {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <b-button size="lg" variant="danger" type="submit" block class="rounded-0 mr-1">
            <i class="fad fa-window-close" />
            İPTAL
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { defineComponent, ref, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    expo.uyeGruplari = ref([]);

    const { form } = toRefs(props);

    const handlerFetchAllData = async () => {
      await store.dispatch('uyeGrupListele');
      expo.uyeGruplari.value = store.getters.getUyeGrupListele;
    };

    handlerFetchAllData();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
