<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
        <b-card no-body class="rounded-0">
          <b-card-header class="bg-white font-weight-bold"> Rehber Bilgisi </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-cols="8" label="Çocuklar için en az bir yetişkinin refakati gerekiyor mu?">
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.refakatci_gereklimi"
                    v-model="form.refakatci_gereklimi"
                    switch
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-cols="8" label="Şahıs rehber veya sesli/yazılı rehber sunuyor musunuz?">
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.rehber_sesli_yazili"
                    v-model="form.rehber_sesli_yazili"
                    switch
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-cols="8" label="Rehberleriniz lisanslı/sertifikalı mı?">
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.rehber_lisanslimi"
                    v-model="form.rehber_lisanslimi"
                    switch
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-cols="8" label="Rehberleriniz şoför olarak da hizmet veriyor mu?">
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.rehber_sofor_hizmeti"
                    v-model="form.rehber_sofor_hizmeti"
                    switch
                    size="lg"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <strong>Dahil edilen rehberin dil bilgisi nedir ?</strong>
              </b-col>
              <b-col cols="12">
                <table class="table table-striped border">
                  <thead class="font-weight-bold">
                    <tr>
                      <th width="40%">Diller</th>
                      <th>Şahıs</th>
                      <th>Sesli</th>
                      <th>Okuma</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(dil, i) in form.rehber_bildigi_diller" :key="i">
                      <td>{{ dil.diller }}</td>
                      <td>
                        <b-form-checkbox
                          size="lg"
                          switch
                          v-model="form.rehber_bildigi_diller[i].sahis"
                          class="custom-control-primary"
                        />
                      </td>
                      <td>
                        <b-form-checkbox
                          size="lg"
                          switch
                          v-model="form.rehber_bildigi_diller[i].sesli"
                          class="custom-control-primary"
                        />
                      </td>
                      <td>
                        <b-form-checkbox
                          size="lg"
                          switch
                          v-model="form.rehber_bildigi_diller[i].okuma"
                          class="custom-control-primary"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card no-body class="rounded-0 mt-2">
          <b-card-header class="bg-white font-weight-bold"> Turla İlgili Diğer Bilgiler </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Asgari Yaş Sınırı" description="Örnek olarak 18 veya 18+ gibi girebilirsiniz.">
                  <b-form-input size="lg" class="rounded-0" v-model="form.asgari_yas" aria-describedby="input-1-live-feedback" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Maksimum Kapasite" description="Örnek olarak 50 girebilirsiniz.">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    v-model="form.maksimum_kisi"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Maksimum Yaş" description="Örnek olarak 50 girebilirsiniz.">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    v-model="form.maksimum_yas"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Toplam Süre" description="Örnek olarak ( 9 Saat, 1 Gün, 2 Hafta ) vb.  girebilirsiniz.">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    v-model="form.toplam_sure[0]"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Toplam Süre Türü" description="Örnek olarak ( Saat, Gün, Hafta ) vb.  girebilirsiniz.">
                  <v-select
                    v-model="form.toplam_sure[1]"
                    :options="toplamSureTuru"
                    :reduce="(tur) => tur.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select select-size-lg d-block"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <div class="d-flex">
              <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
                <i class="fad fa-save" />
                {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { vSelect },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    expo.toplamSureTuru = ref([
      {
        value: 'saat',
        title: 'Saat',
      },
      {
        value: 'gun',
        title: 'Gün',
      },
      {
        value: 'hafta',
        title: 'Hafta',
      },
      {
        value: 'ay',
        title: 'Ay',
      },
    ]);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
