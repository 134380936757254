<template>
  <b-card no-body class="rounded-0 border border-info">
    <b-card-header class="bg-white d-flex justify-content-between align-items-center">
      <h6 class="mt-2 font-weight-bold">{{ variantItem.icerik[defaultDil].baslik }}</h6>
      <div class="d-flex">
        <b-form-checkbox v-model="variant.name" :value="true" switch size="lg" class="pt-1 pr-1" />
        <v-select
          v-model="variant.tipi"
          :options="variantTipi"
          :reduce="(tip) => tip.value"
          label="title"
          :clearable="false"
          class="invoice-filter-select d-block pr-2"
          style="min-width: 200px"
        />

        <v-select
          v-model="ozellik_adi"
          :options="variantItem.ozellik"
          label="baslik"
          :clearable="false"
          class="invoice-filter-select d-block pr-2"
          style="min-width: 200px"
          @input="handlerCreatedOzellik($event)"
        >
          <template slot="selected-option" slot-scope="option">
            {{ option.icerik[defaultDil].baslik }}
          </template>
          <template slot="option" slot-scope="option">
            {{ option.icerik[defaultDil].baslik }}
          </template>
        </v-select>

        <b-button class="rounded-0" variant="outline-danger" @click="handlerRemoveVariant(index)">
          <i class="fad fa-trash" />
        </b-button>
      </div>
    </b-card-header>
    <b-card-body class="p-0 p-2" v-if="form.variant_k_no">
      <draggable v-model="variant.secenekler" handle=".draggable-sort-item">
        <b-card v-for="(item, index) in variant.secenekler" :key="index" class="mt-1">
          <b-card-header class="bg-white border-0 p-0 m-0 d-flex justify-content-between" role="tab">
            <div class="d-flex justify-content-start align-items-center">
              <i class="fad fa-sort fa-2x pr-4 draggable-sort-item" style="cursor: move"></i>
              <strong class="d-flex justify-content-start align-items-center" v-b-toggle="'accordion-' + index">
                <i class="fad fa-money-bill fa-2x pr-2"></i>
                {{ selectItemName(item.k_no) }}
              </strong>
            </div>
            <div class="d-flex">
              <b-button squared size="sm" variant="info" @click="handlerCreatedOzellikFiyat(index)">
                <i class="fad fa-plus"></i>
              </b-button>
              <b-button squared size="sm" variant="danger" @click="handlerRemoveOzellik(index)">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-card-header>

          <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <table class="table table-striped table-sm">
                <thead class="font-weight-bold">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tarih Aralığı</th>
                    <th scope="col" class="text-right">
                      Fiyat
                      <b v-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'tekil'">
                        <template v-if="form.fiyat_kisi_surucu == 'kisi'"> ( Yetişkin | Çocuk | Bebek) </template>
                        <template v-else> ( Sürücü | Yolcu) </template>
                      </b>
                      <b v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'grup'">
                        ( Kapasite: En Az ve Çok, Fiyat, Artı Kişi Fiyat )
                      </b>
                    </th>
                    <th scope="col" class="text-right">İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <UrunVariantMaliyetSatisFiyatItem
                    v-for="(item, i) in item.fiyat"
                    :key="i"
                    :number="i"
                    :item="item"
                    :form="form"
                    :defaultDil="defaultDil"
                    @remove="handlerRemoveFiyat($event)"
                  />
                </tbody>
              </table>
            </b-card-body>
          </b-collapse>
        </b-card>
      </draggable>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import draggable from 'vuedraggable';
import { ref, defineComponent, computed, toRefs } from '@vue/composition-api';
import UrunVariantMaliyetSatisFiyatItem from './UrunVariantMaliyetSatisFiyatItem.vue';

export default defineComponent({
  components: { draggable, vSelect, UrunVariantMaliyetSatisFiyatItem },
  props: {
    index: [Number],
    variant: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    variantlar: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { form, variant, variantlar } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.ozellik_adi = ref(null);

    expo.variantItem = computed(() => variantlar.value.find((x) => x.k_no == variant.value.variant_k_no));

    expo.variantName = computed(() => {
      return (val) => {
        const name = expo.variantlar.value.find((x) => x.k_no == val);
        if (name) {
          return name.icerik[expo.defaultDil.value].baslik;
        } else {
          return null;
        }
      };
    });
    expo.selectItemName = computed(() => {
      return (val) => {
        const name = expo.variantItem.value.ozellik.find((x) => x.k_no == val);
        if (name) {
          return name.icerik[expo.defaultDil.value].baslik;
        } else {
          return null;
        }
      };
    });
    expo.variantTipi = ref([
      {
        value: 'ozellik',
        title: 'Özellik Olarak Ekle',
      },
      {
        value: 'secenek',
        title: 'Seçenek Olarak Ekle',
      },
    ]);

    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

        const url = `${base_url}uploads/${fileName}/urun/${val}`;
        return url;
      }
    };

    expo.handlerRemoveVariant = (event) => {
      const select_var_k_no = form.value.variant_senecekleri[event].variant_k_no;
      form.value.variant_k_no.splice(form.value.variant_k_no.indexOf(select_var_k_no), 1);
      form.value.variant_senecekleri.splice(event, 1);
    };

    expo.handlerRemoveOzellik = (event) => {
      variant.value.secenekler.splice(event, 1);
    };

    expo.handlerCreatedOzellik = (event) => {
      const varmi = variant.value.secenekler.find((x) => x.k_no == event.k_no);
      if (!varmi) {
        variant.value.secenekler.push({
          k_no: event.k_no,
          fiyat: [],
        });
      }
    };

    expo.handlerCreatedOzellikFiyat = (index) => {
      if (form.value.urun_tipi == 'tur') {
        if (form.value.fiyatlandirma == 'tekil') {
          variant.value.secenekler[index].fiyat.push({
            bolge: null,
            bas_tarih: null,
            bit_tarih: null,
            fiyat: { yetiskin: 0, cocuk: 0, bebek: 0 },
            maliyet: { yetiskin: 0, cocuk: 0, bebek: 0 },
          });
        } else {
          variant.value.secenekler[index].fiyat.push({
            bolge: null,
            bas_tarih: null,
            bit_tarih: null,
            fiyat: { en_az: 1, en_cok: 0, fiyat: 0, arti_fiyat: 0 },
            maliyet: { fiyat: 0, arti_fiyat: 0 },
          });
        }
      } else {
        variant.value.secenekler[index].fiyat.push({
          bolge: null,
          bas_tarih: null,
          bit_tarih: null,
          fiyat: 0,
          maliyet: 0,
        });
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
