<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      ref="my-modal"
      centered
      class="rounded-0"
      size="xl"
      title="Adres Tanımla"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-card-text class="p-1">
        <b-row class="mb-2">
          <b-col cols="12" md="6">
            <validation-provider name="Türü" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Türü">
                <b-form-radio-group
                  v-model="form.turu"
                  :options="turuData"
                  value-field="value"
                  text-field="title"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Başlık">
                <b-form-input
                  v-model="form.baslik"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Ad Soyad">
                <b-form-input
                  v-model="form.ad_soyad"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="pt-2">
            <validation-provider name="Adres" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Adres">
                <b-form-textarea
                  v-model="form.adres"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Telefon 1">
                <b-form-input
                  v-model="form.telefon_1"
                  class="rounded-0"
                  placeholder="90 5xx xxx xxxx"
                  v-mask="'## ### ### ####'"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <b-form-group label="Telefon 2">
              <b-form-input
                v-model="form.telefon_2"
                class="rounded-0"
                placeholder="90 5xx xxx xxxx"
                v-mask="'## ### ### ####'"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Şehir" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Şehir">
                <b-form-input
                  v-model="form.sehir"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="İlçe" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="İlçe">
                <b-form-input
                  v-model="form.ilce"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Ülke" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Ülke">
                <b-form-input
                  v-model="form.ulke"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Posta Kodu" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Posta Kodu">
                <b-form-input
                  v-model="form.posta_kodu"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="form.turu == 'bireysel'">
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Tc Kimlik" :rules="{ required: form.turu == 'bireysel' }" v-slot="validationContext">
              <b-form-group label="Tc Kimlik">
                <b-form-input
                  v-model="form.tc_kimlik"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Vergi Dairesi" :rules="{ required: form.turu == 'kurumsal' }" v-slot="validationContext">
              <b-form-group label="Vergi Dairesi">
                <b-form-input
                  v-model="form.vergi_dairesi"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" class="pt-2">
            <validation-provider name="Vergi No" :rules="{ required: form.turu == 'kurumsal' }" v-slot="validationContext">
              <b-form-group label="Vergi No">
                <b-form-input
                  v-model="form.vergi_no"
                  class="rounded-0"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="ok()" :disabled="show">
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            {{ form.k_no ? 'Güncelle' : 'Kaydet' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ref, watch, defineComponent, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
    },
    updateData: {
      type: Object,
    },
    uye_k_no: [String],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { openModal, updateData, uye_k_no } = toRefs(props);
    expo.show = ref(false);
    expo.form = ref({
      uye_k_no: null,
      turu: 'bireysel',
      baslik: null,
      ad_soyad: null,
      adres: null,
      telefon_1: null,
      telefon_2: null,
      sehir: null,
      ilce: null,
      ulke: null,
      posta_kodu: null,
      tc_kimlik: null,
      vergi_no: null,
      vergi_dairesi: null,
    });

    expo.turuData = ref([
      { value: 'bireysel', title: 'Bireysel' },
      { value: 'kurumsal', title: 'Kurumsal' },
    ]);

    expo.tipiData = ref([
      { value: 'kargo', title: 'Kargo Adresi' },
      { value: 'fatura', title: 'Fatura Adresi' },
      { value: 'ayni', title: 'Kargo + Fatura' },
    ]);

    expo.resetForm = () => {
      expo.form.value = {
        uye_k_no: null,
        turu: 'bireysel',

        baslik: null,
        ad_soyad: null,
        adres: null,
        telefon_1: null,
        telefon_2: null,
        sehir: null,
        ilce: null,
        ulke: null,
        posta_kodu: null,
        tc_kimlik: null,
        vergi_no: null,
        vergi_dairesi: null,
      };
    };
    expo.showModal = () => {
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.form.value.uye_k_no = uye_k_no.value;
      const base_url = !expo.form.value.k_no ? 'uyeAdresEkle' : 'uyeAdresGuncelle';
      store.dispatch(base_url, expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success(!expo.form.value.k_no ? 'Ekleme Başarılı' : 'Güncelleme Başarılı', { position: 'bottom-left' });
          context.emit('uyeUpdate', res.data.data);
          if (!expo.form.value.k_no) {
            expo.resetForm();
            expo.hideModal();
          }
        }
      });
    };

    watch(
      openModal,
      (val) => {
        if (val == true) {
          expo.showModal();
          if (Object.keys(updateData.value).length) {
            expo.form.value = updateData.value;
          } else {
            expo.resetForm();
          }
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
