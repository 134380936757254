<template>
  <b-card no-body class="rounded-0 m-2">
    <b-card-header class="bg-white">
      <div class="d-flex justify-content-between align-items-center">
        <strong class="d-flex align-items-center">
          <i class="fad fa-sort fa-2x text-info pr-2 draggable-sort-item" />
          <b-button squared size="sm" :variant="item.baslik[defaultDil] ? 'warning' : 'danger'" @click="openModal = true">
            <i class="fad fa-edit"> </i> {{ item.baslik[defaultDil] || 'Baslik Tanımlanmamış' }}
          </b-button>
        </strong>
        <div class="d-flex">
          <b-form-checkbox v-model="item.default" :value="true" switch size="lg" class="pr-1" v-b-tooltip.hover.top="'Varsayılan Fiyat'" />
          <b-button squared size="sm" variant="info" @click="handlerPeriyotSatisFiyatEkle(index)">
            <i class="fad fa-plus"> </i> {{ form.fiyat_turu == 'donemsel' ? 'Dönem Ekle' : 'Bölge Ekle' }}
          </b-button>
          <b-button squared size="sm" variant="danger" @click="handlerPeriyotSatisFiyatSil(index)">
            <i class="fad fa-trash"> </i>
          </b-button>
        </div>
      </div>
    </b-card-header>
    <b-card-text>
      <table class="table table-striped table-sm">
        <thead class="font-weight-bold">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Tarih Aralığı</th>
            <th scope="col" class="text-right">
              Fiyat
              <b v-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'tekil'">
                <template v-if="form.fiyat_kisi_surucu == 'kisi'"> ( Yetişkin | Çocuk | Bebek ) </template>
                <template v-else> ( Sürücü | Yolcu ) </template>
              </b>
              <b v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'grup'"> ( Kapasite: En Az ve Çok, Fiyat, Artı Kişi Fiyat )</b>
            </th>
            <th scope="col" class="text-right">İşlem</th>
          </tr>
        </thead>
        <tbody>
          <UrunSatisFiyatItem
            v-for="(fiyat, i) in item.fiyat"
            :key="i"
            :number="i"
            :item="fiyat"
            :form="form"
            :defaultDil="defaultDil"
            @remove="handlerRemoveFiyat($event)"
          />
        </tbody>
      </table>
    </b-card-text>
    <UrunSatisFiyatPeriyotBaslikModal :openModal="openModal" @closeModal="openModal = $event" :item="item" />
  </b-card>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import UrunSatisFiyatPeriyotBaslikModal from './UrunSatisFiyatPeriyotBaslikModal.vue';
import UrunSatisFiyatItem from './UrunSatisFiyatItem.vue';
export default {
  components: {
    UrunSatisFiyatItem,
    UrunSatisFiyatPeriyotBaslikModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: {},
    },
    form: {
      type: Object,
      required: true,
      default: {},
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    const { form, index } = toRefs(props);
    expo.openModal = ref(false);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.handlerPeriyotSatisFiyatEkle = (index) => {
      // form.value.satis_fiyat[index].fiyat.push({
      //   bolge: null,
      //   bas_tarih: null,
      //   bit_tarih: null,
      //   fiyat: 0,
      //   maliyet: 0,
      // });

      if (form.value.urun_tipi == 'tur') {
        form.value.satis_fiyat[index].fiyat.push({
          bolge: null,
          bas_tarih: null,
          bit_tarih: null,
          ...(form.value.fiyatlandirma == 'tekil' && {
            fiyat: { yetiskin: 0, cocuk: 0, bebek: 0 },
            maliyet: { yetiskin: 0, cocuk: 0, bebek: 0 },
          }),
          ...(form.value.fiyatlandirma == 'grup' && {
            fiyat: { en_az: 1, en_cok: 0, fiyat: 0, arti_fiyat: 0 },
            maliyet: { fiyat: 0, arti_fiyat: 0 },
          }),
        });
      } else {
        form.value.satis_fiyat[index].fiyat.push({
          bolge: null,
          bas_tarih: null,
          bit_tarih: null,
          fiyat: 0,
          maliyet: 0,
        });
      }
    };

    expo.handlerPeriyotSatisFiyatSil = (index) => {
      form.value.satis_fiyat.splice(index, 1);
    };

    expo.handlerRemoveFiyat = (i) => {
      form.value.satis_fiyat[index.value].fiyat.splice(i, 1);
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
