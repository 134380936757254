<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-transparent font-weight-bold d-flex justify-content-between">
              <span><i class="fad fa-location"></i> Konum Bilgisi </span>
              <span class="font-weight-light">
                <b-link href="https://yandex.com/map-constructor" target="_blank"> Yandex Maps</b-link> |
                <b-link href="https://www.google.com/maps/" target="_blank"> Google Maps</b-link>
              </span>
            </b-card-header>
            <b-card-text class="p-2">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Konum Bilgisi">
                    <b-form-textarea class="rounded-0" rows="4" v-model="form.konum"></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Konum Adres Bilgisi">
                    <b-form-input class="rounded-0" rows="4" v-model="form.konum_adres"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" style="max-height: 200px; overflow: hidden" v-if="form.konum" v-html="form.konum"></b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card no-body class="rounded-0 h-100">
            <b-card-header class="bg-transparent font-weight-bold">
              <i class="fad fa-concierge-bell"></i> Ekstra Hizmetler
            </b-card-header>
            <b-card-text class="p-2">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Hizmet Bilgisi"
                    description="Bu ürünü alanların hangi ektra hizmet satın alabileceklerini belirleyiniz."
                  >
                    <v-select
                      v-model="form.ekstra_hizmet_k_no"
                      :options="ektraHizmetler"
                      :reduce="(sayfa) => sayfa.k_no"
                      label="baslik"
                      :clearable="false"
                      multiple
                      class="invoice-filter-select d-block"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
            <i class="fad fa-save" />
            {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import UrunVariantItem from './UrunVariantItem.vue';
export default defineComponent({
  components: {
    vSelect,
    UrunVariantItem,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { form } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.ektraHizmetler = computed(() =>
      store.getters.getEkstraHizmetler.filter((x) => x.urun_grubu.includes(form.value.urun_tipi))
    );

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
