<template>
  <div>
    <table class="table table-sm table-striped">
      <thead>
        <tr class="font-weight-bold">
          <th scope="col"><i class="fad fa-sort"></i></th>
          <th scope="col">#</th>
          <th scope="col">Ana Bölge</th>
          <th scope="col">Bölge</th>
          <th scope="col" class="text-right">Bölge Alış Saati</th>
          <th scope="col" class="text-right">
            <b-button size="sm" squared variant="primary" v-b-tooltip.hover.top="'Bölge Ata'" @click="openModal = true">
              BÖLGE ATA
            </b-button>
          </th>
        </tr>
      </thead>
      <draggable v-model="form.bolge_alis" tag="tbody" handle=".draggable-task-handle">
        <tr v-for="(bolge, index) in form.bolge_alis" :key="index">
          <td><i class="fad fa-sort fa-2x draggable-task-handle" style="cursor: move"></i></td>
          <th scope="row">
            {{ index + 1 }}
          </th>
          <th scope="row">{{ bolgeTitle(bolge.ana_k_no) }}</th>
          <td>{{ bolgeTitle(bolge.k_no) }}</td>
          <td>
            <b-form-input
              v-model="bolge.sure"
              v-b-tooltip.hover.top="'Bölgeden Misafirin Alınış Saatini Belirleyiniz'"
              size="sm"
              v-mask="'##:##'"
              class="rounded-0 float-right w-50"
            />
          </td>
          <td class="text-right">
            <b-button size="sm" squared variant="danger" @click="handlerRemoveBolge(bolge)">
              <i class="fad fa-trash"></i>
            </b-button>
          </td>
        </tr>
      </draggable>
    </table>

    <b-button type="submit" block squared size="lg" variant="primary" @click="$emit('onSubmit', true)">
      <i class="fad fa-save"></i> GÜNCELLE
    </b-button>
    <UrunTurBolgeAlisEkle :form="form" :openModal="openModal" @closeModal="openModal = $event" />
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, toRefs, computed } from '@vue/composition-api';
import UrunTurBolgeAlisEkle from './UrunTurBolgeAlisEkle.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
export default defineComponent({
  components: { draggable, UrunTurBolgeAlisEkle },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { form } = toRefs(props);
    expo.openModal = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    const tumBolgeler = computed(() => store.getters.getTumBolgeler.filter((x) => x.statu == true));

    expo.bolgeTitle = computed(() => {
      return (val) => {
        const bolge = tumBolgeler.value.find((x) => x.k_no == val);

        if (bolge != undefined) {
          return bolge.icerik[expo.defaultDil.value].baslik;
        } else {
          return '';
        }
      };
    });

    expo.handlerRemoveBolge = (event) => {
      form.value.bolge_alis.splice(form.value.bolge_alis.indexOf(event), 1);
    };

    // expo.onSubmit = () => {
    //   context.emit('show', true);
    //   store
    //     .dispatch('transferTarifeBolgeGuncelle', form.value)
    //     .then((res) => {
    //       if (res.data.success == true) {
    //         toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
    //         context.emit('show', false);
    //         context.emit('updateRefresh', res.data.data);
    //       }
    //     })
    //     .catch((err) => {
    //       if (err.success === false) {
    //         toast.success(err.message, { position: 'bottom-left' });
    //       }
    //     });
    // };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
