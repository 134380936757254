<template>
  <div>
    <b-modal
      lazy
      :visible="openModal"
      @change="(val) => $emit('closeModal', false)"
      class="rounded-0"
      centered
      title="Periyot Başlık Güncelle"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" v-for="(bas, index) in diller" :key="index">
            <b-form-group label-cols="5" :label="bas.title">
              <b-form-input size="lg" class="rounded-0" v-model="item.baslik[bas.lang]" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button size="lg" squared variant="primary" class="float-right" type="submit" @click="$emit('closeModal', false)">
            <i class="fad fa-save" />
            Güncelle
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import { langs } from '@/libs/languages';
export default {
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, context) {
    const expo = {};

    expo.diller = computed(() => store.getters.getDilAyarlari.diller);

    expo.langTitle = computed(() => {
      return (val) => {
        const dil = expo.diller.value.find((x) => x.lang == val);
        console.log(dil);
        return dil;
      };
    });

    expo.onSubmit = () => {};

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
