<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      ref="my-modal"
      centered
      class="rounded-0"
      title="Kupon Tanımla"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-card-text class="p-1">
        <b-row>
          <b-col cols="12">
            <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Başlık" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                <v-select
                  v-model="form.kupon_k_no"
                  :options="kuponlar"
                  :reduce="(kupon) => kupon.k_no"
                  label="baslik"
                  :clearable="false"
                  class="invoice-filter-select d-block select-size-lg"
                  :class="{ 'is-invalid': !!validationContext.errors[0] }"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mt-4">
            <b-form-group label-cols="5" label="Statü">
              <b-form-checkbox class="float-right" :checked="form.statu" v-model="form.statu" switch size="lg" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="ok()" :disabled="show">
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            {{ form.k_no ? 'Güncelle' : 'Kaydet' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { ref, watch, defineComponent, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
    },
    updateData: {
      type: Object,
    },
    uye_k_no: [String],
    kuponlar: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { openModal, updateData, uye_k_no } = toRefs(props);
    expo.show = ref(false);
    expo.form = ref({
      uye_k_no: null,
      kupon_k_no: null,
      statu: true,
    });

    expo.resetForm = () => {
      expo.form.value = {
        uye_k_no: null,
        kupon_k_no: null,
        statu: true,
      };
    };
    expo.showModal = () => {
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.form.value.uye_k_no = uye_k_no.value;
      const base_url = !expo.form.value.k_no ? 'uyeKuponEkle' : 'uyeKuponGuncelle';
      store.dispatch(base_url, expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success(!expo.form.value.k_no ? 'Ekleme Başarılı' : 'Güncelleme Başarılı', { position: 'bottom-left' });
          context.emit('uyeUpdate', res.data.data);
          if (!expo.form.value.k_no) {
            expo.resetForm();
            expo.hideModal();
          }
        }
      });
    };

    watch(
      openModal,
      (val) => {
        if (val == true) {
          expo.showModal();
          if (Object.keys(updateData.value).length) {
            expo.form.value = updateData.value;
          } else {
            expo.resetForm();
          }
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
