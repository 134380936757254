<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="8">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Başlık">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="baslik"
                      v-model="form.icerik[defaultDil].baslik"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerBaslikChange"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="!form.type">
              <b-col cols="12">
                <validation-provider name="Ön Açıklama" :rules="{ required: !form.type, max: 300 }" v-slot="validationContext">
                  <b-form-group
                    :label="`Ön Açıklama ${onAciklamaLenght} / 300`"
                    description="Ön Açıklama 160 karakteri geçmemelidir"
                  >
                    <b-form-textarea
                      class="rounded-0"
                      ref="on_aciklama"
                      v-model="form.icerik[defaultDil].on_aciklama"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerOnAcklamaChange"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" class="">
                <validation-provider name="Açıklama" :rules="{ required: !form.type }" v-slot="validationContext">
                  <b-form-group label="Açıklama" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                    <ckeditor
                      :editor="editor"
                      v-model="form.icerik[defaultDil].aciklama"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      style="height: 400px !importat"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Slug (Seflink)"
                  description="Bu link yapısı bir kere oluşturulacaktır. Düzenleme yaptığınız takdirde Google,Yandex vb siteler indexlemeyi ortalama 1 ay için de tekrardan bu sayfayı indexleme alacaktır."
                >
                  <b-input-group>
                    <b-form-input size="lg" class="rounded-0" v-model="form.icerik[defaultDil].slug" :disabled="!slug_duzenle" />
                    <b-input-group-append v-if="form.k_no">
                      <b-button squared variant="outline-success" @click="slug_duzenle = !slug_duzenle">
                        <i class="fad fa-pencil"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Video">
                  <b-form-input size="lg" class="rounded-0" ref="video" v-model="form.icerik[defaultDil].video" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Taglar">
                  <b-form-tags
                    size="lg"
                    v-model="form.icerik[defaultDil].keywords"
                    input-id="tags-basic"
                    class="mb-2 rounded-0"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12">
                <table class="table table-sm table-hover table-striped table-bordered mb-2">
                  <thead>
                    <tr>
                      <th scope="col" width="20%">İcon</th>
                      <th scope="col" width="25%">Baslik</th>
                      <th scope="col">Aciklama</th>
                      <th scope="col" width="1%">
                        <b-button
                          variant="outline-success"
                          size="sm"
                          class="btn-icon"
                          @click="$emit('handlerOzellestir', $event)"
                        >
                          <i class="fad fa-plus"></i>
                        </b-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ozel, i) in form.ozel_icerik" :key="i">
                      <td>
                        <b-form-input class="rounded-0" v-model="ozel.icon" aria-describedby="input-1-live-feedback" />
                      </td>
                      <td>
                        <validation-provider name="Başlık" :rules="{ required: form.type }" v-slot="validationContext">
                          <b-form-input
                            class="rounded-0"
                            ref="baslik"
                            v-model="ozel[defaultDil].baslik"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          />
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider name="Açıklama" :rules="{ required: form.type }" v-slot="validationContext">
                          <b-form-textarea
                            class="rounded-0"
                            ref="on_aciklama"
                            rows="5"
                            v-model="ozel[defaultDil].aciklama"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          />
                        </validation-provider>
                      </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          squared
                          size="sm"
                          class="btn-icon"
                          :disabled="i == 0"
                          @click="$emit('createOzelIcerikDilSil', i)"
                        >
                          <i class="fad fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Özellestir">
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.type"
                    v-model="form.type"
                    switch
                    size="lg"
                    @change="$emit('handlerOzellestir', $event)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" v-show="diller.length != 1">
                <b-form-group label-cols="5" label="Dil">
                  <v-select
                    v-model="defaultDil"
                    :options="diller"
                    :reduce="(diller) => diller.lang"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                    :disabled="!form.k_no"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Yayın Durumu">
                  <v-select
                    v-model="form.statu"
                    :options="durumlar"
                    :reduce="(durum) => durum.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Anasayfa Gösterim">
                  <v-select
                    v-model="form.anasayfa"
                    :options="durumlar"
                    :reduce="(durum) => durum.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Kategoriler">
                  <v-select
                    v-model="form.kategori_k_no"
                    :options="kategoriler"
                    :reduce="(kategori) => kategori.k_no"
                    label="baslik"
                    :clearable="true"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="h6">Seo Optimize</label>
                  <b-form-checkbox :checked="form.seo" v-model="form.seo" switch size="lg" />
                </div>
              </b-col>
              <b-col cols="12">
                <b-button
                  size="lg"
                  :variant="form.k_no == null ? 'success' : 'warning'"
                  type="submit"
                  block
                  class="rounded-0 mr-1"
                >
                  <i class="fad fa-save" />
                  <span class="align-middle pl-2">{{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { defineComponent, ref, toRefs } from '@vue/composition-api';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import slugify from 'slugify';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    kategoriler: {
      type: Array,
      required: true,
    },
    diller: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    const { form } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.onAciklamaLenght = ref(0);
    expo.editor = ref(ClassicEditor);

    expo.slug_duzenle = ref(false);

    expo.durumlar = ref([
      { id: true, title: 'Evet' },
      { id: false, title: 'Hayır' },
    ]);

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      if (!form.value.k_no || expo.slug_duzenle.value) form.value.icerik[expo.defaultDil.value].slug = slug;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
