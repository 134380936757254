<template>
  <tr>
    <th class="text-center">
      <h6 class="mt-2">{{ number + 1 }}</h6>
    </th>
    <td>
      <!-- {{ fiyatBolgeleri }} -->
      <validation-provider name="Bölge" :rules="{ required: true }" v-if="fiyatBolgeleri.length && form.fiyat_turu == 'bolgesel'">
        <v-select
          v-model="item.bolge"
          :options="fiyatBolgeleri"
          :reduce="(fiyat) => fiyat.k_no"
          label="baslik"
          :clearable="true"
          multiple
          class="invoice-filter-select select-size-sm d-block"
          style="min-width: 100px; max-width: 300px"
          @input="createOzelAlan($event)"
        >
          <template slot="selected-option" slot-scope="option">
            {{ option.icerik[defaultDil].baslik }}
          </template>
          <template slot="option" slot-scope="option">
            {{ option.icerik[defaultDil].baslik }}
          </template>
        </v-select>
      </validation-provider>
      <validation-provider name="Tarih" :rules="{ required: true }" v-else>
        <flat-pickr
          v-model="date"
          class="form-control rounded-0 bg-white"
          :config="config"
          :events="config.events"
          @on-change="handlerOnChangeDate($event)"
        />
      </validation-provider>
    </td>
    <td style="max-width: 250px" class="text-right">
      <div class="w-100 d-flex justify-content-end" v-if="form.urun_tipi != 'tur'">
        <b-form-group label="Fiyat">
          <b-form-input
            size="sm"
            type="number"
            class="rounded-0 text-right"
            ref="baslik"
            v-model="item.fiyat"
            placeholder="Fiyat"
            style="width: 150px"
          />
        </b-form-group>
      </div>

      <div class="w-100 d-flex justify-content-end" v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'tekil'">
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.yetiskin"
          placeholder="Yetişkin Fiyat"
          style="width: 150px"
          v-b-tooltip.hover.top="form.fiyat_kisi_surucu == 'kisi' ? 'Yetişkin Fiyat' : 'Sürücü Fiyat'"
        />
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.cocuk"
          placeholder="Çocuk Fiyat"
          style="width: 150px"
          v-b-tooltip.hover.top="form.fiyat_kisi_surucu == 'kisi' ? 'Çocuk Fiyat' : 'Yolcu Fiyat'"
        />
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.bebek"
          placeholder="Bebek Fiyat"
          style="width: 150px"
          v-b-tooltip.hover.top="'Bebek Fiyat'"
          v-if="form.fiyat_kisi_surucu == 'kisi'"
        />
      </div>

      <div class="w-100 d-flex justify-content-end" v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'grup'">
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.en_az"
          placeholder="Kap. En Az"
          style="width: 150px"
          v-b-tooltip.hover.top="'Kap. En Az'"
        />
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.en_cok"
          placeholder="Kap. En Çok"
          style="width: 150px"
          v-b-tooltip.hover.top="'Kap. En Çok'"
        />
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.fiyat"
          placeholder="Grup Fiyat"
          style="width: 150px"
          v-b-tooltip.hover.top="'Grup Fiyat'"
        />
        <b-form-input
          size="sm"
          type="number"
          class="rounded-0 text-right"
          ref="baslik"
          v-model="item.fiyat.arti_kisi"
          placeholder="Artı Kişi Fiyat"
          style="width: 150px"
          v-b-tooltip.hover.top="'Artı Kişi Fiyat'"
        />
      </div>
    </td>
    <td class="text-right">
      <b-button size="sm" class="rounded-0" variant="outline-danger" @click="$emit('remove', number)">
        <i class="fad fa-trash" />
      </b-button>
    </td>
  </tr>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { ref, defineComponent, computed, toRefs, watch } from '@vue/composition-api';

import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
export default defineComponent({
  components: { vSelect, flatPickr },
  props: {
    number: [Number],
    item: { type: Object, required: true },
    form: { type: Object, required: true },
    defaultDil: [String],
  },
  setup(props) {
    const expo = {};
    const { item, form } = toRefs(props);
    expo.bolgeler = computed(() => store.getters.getTumBolgeler);
    expo.fiyatBolgeleri = ref([]);
    expo.date = ref(null);
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      showMonths: 2,
      mode: 'range',
      // minDate: 'today',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
      allowInput: true,
    });

    expo.handlerOnChangeDate = (event) => {
      if (event.length > 0) {
        item.value.bas_tarih = moment(String(event[0])).valueOf();
        item.value.bit_tarih = moment(String(event[1])).valueOf();
      }
    };

    expo.UrunOzellikTurleri = ref([
      {
        value: 'input',
        title: 'İnput Form',
      },
      {
        value: 'Checkbox Form',
        title: 'checkbox',
      },
    ]);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    const handlerBolgeler = () => {
      const bolge = form.value.bolge_k_no;

      bolge.forEach((el) => {
        if (expo.bolgeler.value.length) {
          const b = expo.bolgeler.value.find((x) => x.k_no == el);
          if (b != undefined) expo.fiyatBolgeleri.value.push(b);
        }
      });
    };

    watch(
      item.value,
      (val) => {
        expo.date.value = moment(val.bas_tarih).format('DD.MM.YYYY') + ' - ' + moment(val.bit_tarih).format('DD.MM.YYYY');
      },
      { deep: true, immediate: true }
    );

    watch(
      expo.bolgeler,
      () => {
        handlerBolgeler();
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
