<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6" v-if="kampanyalar.length > 0">
          <b-form-group label="Kampanyalar ( Opsiyonel )" description="Ürüne Kampanya Belirleyebilirsiniz.">
            <v-select
              v-model="form.kampanya_k_no"
              :options="kampanyalar"
              :reduce="(kampanya) => kampanya.k_no"
              label="baslik"
              :clearable="true"
              class="invoice-filter-select d-block select-size-lg pr-2"
              style="min-width: 280px"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="font-weight-bold">Üyelere Özel İndirim Tanımlama </span>
                  <b-button
                    size="sm"
                    class="rounded-0"
                    variant="outline-info"
                    @click="$emit('createOzelFiyat', true)"
                    v-b-tooltip.hover="'Üyelere Özel Fiyat Tanımlayabilirsiniz'"
                  >
                    Özel İndirim Tanımla
                  </b-button>
                </div>
              </b-col>
              <b-col cols="12" v-if="form.ozel_satis_fiyat">
                <b-row v-for="(ozel, index) in form.ozel_satis_fiyat" :key="index">
                  <b-col cols="12" md="4">
                    <div class="d-flex justify-content-between align-items-center pt-2">
                      <span class="font-weight-bold">İndirim </span>
                      <b-button size="sm" class="rounded-0" variant="outline-danger" @click="$emit('removeOzelFiyat', index)">
                        <i class="fad fa-trash"></i>
                      </b-button>
                    </div>
                  </b-col>
                  <b-col cols="12" md="8">
                    <b-form-group>
                      <b-input-group>
                        <template #prepend>
                          <v-select
                            v-model="form.ozel_satis_fiyat[index].uye_grup_k_no"
                            :options="uyeGruplari"
                            :reduce="(statu) => statu.k_no"
                            label="baslik"
                            :clearable="false"
                            class="invoice-filter-select d-block select-size-lg"
                            style="min-width: 170px"
                            @input="handlerSelectedUyeGrup(index, $event)"
                          />
                        </template>
                        <b-form-input
                          size="lg"
                          class="rounded-0 text-right"
                          ref="baslik"
                          v-model="form.ozel_satis_fiyat[index].oran"
                          disabled
                        />
                        <b-input-group-prepend is-text> % </b-input-group-prepend>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
              <i class="fad fa-save" />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import UrunVariantItem from './UrunVariantItem.vue';
export default defineComponent({
  components: {
    vSelect,
    UrunVariantItem,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { form } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kampanyalar = computed(() => store.getters.getKampanyalar);
    expo.uyeGruplari = computed(() => store.getters.getUyeGrupListele);

    expo.handlerSelectedUyeGrup = (index, event) => {
      const group = expo.uyeGruplari.value.find((x) => x.k_no === event);
      form.value.ozel_satis_fiyat[index].oran = group.oran;
    };

    expo.fiyatTuru = ref([
      {
        value: 'net_fiyat',
        title: 'Net Fiyat',
      },
      {
        value: 'fiyat_fark',
        title: 'Fiyat Farkı',
      },
    ]);

    expo.createVariant = (event) => {
      event.map((el) => {
        const varmi = form.value.variant_senecekleri.find((x) => x.variant_k_no == el);
        if (!varmi) {
          const variant = expo.variantlar.value.find((x) => x.k_no == el);
          form.value.variant_senecekleri.push({
            variant_k_no: el,
            variant_adi: variant.icerik,
            tipi: 'ozellik',
            ozellik: variant.ozellik,
            secenekler: [],
          });
        }
      });
    };

    expo.handlerSelectedColor = (event) => {};

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
