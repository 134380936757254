<template>
  <b-card class="rounded-0 mt-2" header-tag="header" footer-tag="footer">
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h6 class="mb-0">{{ item.baslik }}</h6>
        <span>
          <b-badge variant="info">{{ turuData(item.turu) }}</b-badge>
          <b-badge variant="warning">{{ tipiData(item.adres_tipi) }}</b-badge>
        </span>
      </div>
    </template>
    <b-card-text>
      <p><i class="fad fa-map-pin" /> {{ item.adres }}</p>
      <span> <i class="fad fa-phone" /> {{ item.telefon_1 }}</span>
      <span> <i class="fad fa-phone" /> {{ item.telefon_2 }}</span>
      <br />
      <span>
        <i class="fad fa-city" /> {{ item.sehir }} | {{ item.ilce }} |
        {{ item.ulke }}
      </span>
      <br />
      <span v-if="item.turu == 'bireysel'"> <i class="fad fa-passport" /> {{ item.tc_kimlik }} </span>
      <span v-else>
        <i class="fad fa-passport" /> {{ item.vergi_dairesi }} |
        {{ item.vergi_no }}
      </span>
    </b-card-text>
    <template #footer>
      <div class="d-flex justify-content-end">
        <b-button-group>
          <b-button squared variant="danger" @click="handlerRemove(item.k_no)"> <i class="fad fa-trash" /> Sil </b-button>
          <b-button squared variant="warning" @click="$emit('handlerUpdate', item)">
            <i class="fad fa-pencil" /> Güncelle
          </b-button>
        </b-button-group>
      </div>
    </template>
  </b-card>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { defineComponent, computed, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import AdresEkle from './AdresEkle.vue';
export default defineComponent({
  components: {
    vSelect,
    AdresEkle,
  },
  props: {
    index: {
      type: Number,
      requried: true,
    },
    item: {
      type: Object,
      requried: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { item } = toRefs(props);

    expo.turuData = computed(() => {
      return (val) => (val == 'bireysel' ? 'Bireysel' : 'Kurumsal');
    });

    expo.tipiData = computed(() => {
      return (val) => (val == 'kargo' ? 'Kargo Adresi' : val == 'fatura' ? 'Fatura Adresi' : 'Kargo + Fatura');
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('uyeAdresSil', k_no).then((res) => {
            toast.error('Silme Başarılı', { position: 'bottom-left' });
            context.emit('uyeAdresSil', k_no);
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
