<template>
  <b-modal :visible="openModal" @change="(val) => $emit('closeModal', false)" size="lg" class="rounded-0" centered title="Alış Bölgesi Ekle">
    <b-card-text>
      <b-alert v-if="!bolgeler" show variant="warning" class="text-center">
        <p>Gösterilecek veri yok !</p>
      </b-alert>
      <table class="table table-sm table-striped" v-else>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Ana Bölge</th>
            <th scope="col">Bölge</th>
          </tr>
        </thead>
        <tbody>
          <tr :class="bolge.check && 'table-success'" style="cursor: pointer" v-for="(bolge, index) in bolgeler" :key="index" @click="bolge.check = !bolge.check">
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>{{ bolgeTitle(bolge.ana_k_no) }}</td>
            <td>{{ bolgeTitle(bolge.k_no) }}</td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
    <template #modal-footer>
      <div class="w-100">
        <b-button size="sm" squared variant="primary" class="float-right" type="submit" @click="onSubmit">
          <i class="fad fa-save" />
          Seçilenleri Aktar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed, onMounted } from '@vue/composition-api';

export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { form, openModal } = toRefs(props);
    expo.bolgeler = ref(null);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    const tumBolgeler = computed(() => store.getters.getTumBolgeler.filter((x) => x.statu == true));

    expo.bolgeTitle = computed(() => {
      return (val) => {
        const bolge = tumBolgeler.value.find((x) => x.k_no == val);

        if (bolge != undefined) {
          return bolge.icerik[expo.defaultDil.value].baslik;
        } else {
          return '';
        }
      };
    });

    const handlerBolgeler = () => {
      expo.bolgeler.value = [];
      const bolge = store.getters.getTumBolgeler.filter((x) => x.statu == true);
      const bolgeAlis = form.value.bolge_alis;

      bolge.forEach((el) => {
        if (el.bolge_k_no != null) {
          const ana_bolge = bolge.find((x) => x.k_no == el.bolge_k_no);
          if (ana_bolge != undefined) {
            expo.bolgeler.value.push({
              check: false,
              k_no: el.k_no,
              ana_k_no: ana_bolge.k_no,
              sure: null,
            });
          }
        }
      });

      let common = expo.bolgeler.value.filter((obj1) => bolgeAlis.some((obj2) => obj1.k_no === obj2.k_no));

      common.forEach((el) => {
        expo.bolgeler.value.splice(expo.bolgeler.value.indexOf(el), 1);
      });
    };

    handlerBolgeler();

    expo.onSubmit = () => {
      const selectedBolge = expo.bolgeler.value.filter((x) => x.check == true);
      if (selectedBolge.length) {
        selectedBolge.forEach((el) => {
          if (form.value.bolge_alis.indexOf(el) === -1) {
            form.value.bolge_alis.push(el);
          }
        });
        context.emit('closeModal', false);
      } else {
        toast.warning('Lütfen Bölge Seçimi Yapınız');
      }
    };

    watch(
      openModal,
      (val) => {
        if (val) handlerBolgeler();
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
