<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row class="px-4">
        <b-col cols="12" v-if="urunOzelAlanlar.length > 0">
          <b-form-group label="Özel Alan Seneceği">
            <v-select
              v-model="form.ozel_alanlar_k_no"
              :options="urunOzelAlanlar"
              :reduce="(ozel) => ozel.k_no"
              label="baslik"
              :clearable="true"
              multiple
              class="invoice-filter-select d-block select-size-lg pr-2"
              style="min-width: 280px"
              @input="createOzelAlan($event)"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <draggable v-model="form.ozel_alanlar" handle=".draggable-task-handle">
            <b-card
              no-body
              class="rounded-0 mt-2 border-primary draggable-task-handle"
              v-for="(ozel, index) in form.ozel_alanlar"
              :key="index"
            >
              <b-card-header class="bg-white font-weight-bold">
                <div class="d-flex justify-content-between align-items-center">
                  <span> {{ ozelAlanBaslik(ozel.k_no) }}</span>
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="form.ozel_alanlar[index].default"
                      :value="true"
                      switch
                      size="lg"
                      @change="handlerOzellikDefault(index)"
                    />
                    <b-button-group>
                      <b-button squared size="sm" variant="danger" @click="handlerRemoveOzelAlan(index)">
                        <i class="fad fa-trash" />
                      </b-button>
                      <b-button
                        squared
                        v-if="ozel.ozellik.length != newOzelAlanVarmi(ozel.k_no)"
                        size="sm"
                        variant="info"
                        @click="createOzelAlan(form.ozel_alanlar_k_no)"
                      >
                        <i class="fad fa-trash" /> Yeni Alanlar Mevcut
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </b-card-header>
              <b-card-text class="px-2">
                <b-row>
                  <b-col cols="12" md="4" v-for="(sub, i) in ozel.ozellik" :key="i">
                    <b-card no-body class="rounded-0 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                          <span class="pr-2"><i :class="`fad fa-${ozelAlanSubBaslik(ozel.k_no, sub.k_no).icon} fa-2x`" /></span>
                          <span
                            style="
                              text-overflow: ellipsis;
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                            "
                          >
                            {{ ozelAlanSubBaslik(ozel.k_no, sub.k_no).icerik[defaultDil].baslik }}
                          </span>
                        </div>
                        <div class="d-flex justify-content-end">
                          <b-form-checkbox
                            v-model="form.ozel_alanlar[index].ozellik[i].value"
                            :value="true"
                            switch
                            size="lg"
                            v-if="ozelAlanSubBaslik(ozel.k_no, sub.k_no).turu == 'checkbox'"
                          />
                          <b-form-input
                            size="sm"
                            class="rounded-0"
                            v-model="form.ozel_alanlar[index].ozellik[i].value"
                            style="max-width: 100px"
                            v-else
                          />
                          <b-button size="sm" squared variant="danger" @click="handlerOzellikItemRemove(index, i)">
                            <i class="fad fa-trash"></i>
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </draggable>
        </b-col>
      </b-row>
      <b-row class="px-4"> </b-row>
      <b-row class="px-4">
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
              <i class="fad fa-save" />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import draggable from 'vuedraggable';
import { ref, defineComponent, computed, toRefs, watch } from '@vue/composition-api';
export default defineComponent({
  components: { vSelect, draggable },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { form } = toRefs(props);
    expo.defaultDil = computed(() => store.getters.getDilAyarlari.varsayilan);
    expo.urunOzelAlanlar = computed(() => store.getters.getUrunOzelAlanlar.filter((x) => x.turu.includes(form.value.urun_tipi)));

    expo.ozelAlanBaslik = computed(() => {
      return (val) => {
        const ozel = expo.urunOzelAlanlar.value.find((x) => x.k_no == val);
        if (ozel != undefined) return ozel.icerik[expo.defaultDil.value].baslik;
      };
    });

    expo.newOzelAlanVarmi = computed(() => {
      return (val) => {
        const ozel = expo.urunOzelAlanlar.value.find((x) => x.k_no == val);
        if (ozel != undefined) return ozel.ozellik.length;
      };
    });

    expo.ozelAlanSubBaslik = computed(() => {
      return (index, val) => {
        if (expo.urunOzelAlanlar.value.length) {
          const ozellik = expo.urunOzelAlanlar.value.find((x) => x.k_no == index).ozellik;
          if (ozellik != undefined) {
            const values = ozellik.find((x) => x.k_no == val);
            return values;
          }
        }
      };
    });

    expo.handlerRemoveOzelAlan = (event) => {
      const select_k_no = form.value.ozel_alanlar[event].k_no;
      form.value.ozel_alanlar_k_no.splice(form.value.ozel_alanlar_k_no.indexOf(select_k_no), 1);
      form.value.ozel_alanlar.splice(event, 1);
    };

    expo.createOzelAlan = (event) => {
      event.map((el) => {
        const varmi = form.value.ozel_alanlar.find((x) => x.k_no == el);

        if (!varmi) {
          const alanlar = expo.urunOzelAlanlar.value.find((x) => x.k_no == el);
          const ozellik = [];

          alanlar.ozellik.map((el) => {
            ozellik.push({ k_no: el.k_no, value: el.turu == 'checkbox' ? false : '' });
          });

          form.value.ozel_alanlar.push({
            k_no: el,
            default: false,
            ozellik,
          });
        } else {
          const alanlar = expo.urunOzelAlanlar.value.find((x) => x.k_no == el);
          const index = form.value.ozel_alanlar.findIndex((x) => x.k_no == el);
          const eski_ozellik = form.value.ozel_alanlar.find((x) => x.k_no == el).ozellik;
          const yeni_ozellik = [];
          alanlar.ozellik.map((el) => {
            yeni_ozellik.push({ k_no: el.k_no, value: el.turu == 'checkbox' ? false : '' });
          });

          var new_ozellik = Object.assign(yeni_ozellik, eski_ozellik);

          form.value.ozel_alanlar[index].ozellik = new_ozellik;
          form.value.ozel_alanlar[index].default = false;
        }
      });
    };

    expo.handlerOzellikDefault = (index) => {
      form.value.ozel_alanlar.forEach((element, i) => {
        form.value.ozel_alanlar[i].default = false;
      });
      form.value.ozel_alanlar[index].default = true;
    };

    expo.handlerOzellikItemRemove = (index, i) => {
      form.value.ozel_alanlar[index].ozellik.splice(i, 1);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
